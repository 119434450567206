import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="DateTime Scalar specification" mdxType="SEO" />
    <p><strong parentName="p">{` Status: Work in progress `}</strong>{` 🚧 `}</p>
    <h1>DateTime Scalar spec</h1>
    <div {...{
      "className": "toc"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#based-on-rfc-3339"
          }}>{`Based on RFC 3339`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#only-date-time"
            }}>{`Only "date-time"`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#non-optional-exact-milliseconds"
            }}>{`Non optional exact milliseconds`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#no-unknown-local-offset-convention"
            }}>{`No 'Unknown Local Offset Convention'`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#result-coercion"
          }}>{`Result Coercion`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#input"
            }}>{`Input`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#output"
            }}>{`Output`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#literal-coercion"
          }}>{`Literal Coercion`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#input-1"
            }}>{`Input`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#output-1"
            }}>{`Output`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#value-coercion"
          }}>{`Value Coercion`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#input-2"
            }}>{`Input`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#output-2"
            }}>{`Output`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#test-cases"
          }}>{`Test cases`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#recommended-scalar-name"
          }}>{`Recommended Scalar name`}</a></p>
      </li>
    </ul>
    <undefined></undefined>
    <p>{`Specification for a custom GraphQL Scalar which represents an exact point in time. This point in time is specified by having an offset to UTC and does not use time zone.  `}</p>
    <h1 {...{
      "id": "based-on-rfc-3339",
      "style": {
        "position": "relative"
      }
    }}>{`Based on RFC 3339`}</h1>
    <p>{`This Scalar is a slightly refined version of `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc3339"
      }}>{`RFC 3339`}</a>{` including the `}<a parentName="p" {...{
        "href": "https://www.rfc-editor.org/errata/rfc3339"
      }}>{`errata`}</a>{`.`}</p>
    <p>{`All definitions of RFC 3339 are adopted and nothing is added or removed unless explicitly mentioned here.`}</p>
    <p>{`The following refinements/clarifications apply:`}</p>
    <h2 {...{
      "id": "only-date-time",
      "style": {
        "position": "relative"
      }
    }}>{`Only "date-time"`}</h2>
    <p>{`This scalar represents a “date-time” as specified in `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc3339#section-5.6"
      }}>{`section 5.6 of RFC 3339`}</a>{`. `}</p>
    <p>{`The other productions in section 5.6 are only used to support "date-time" but never stand alone for this Scalar. `}</p>
    <h2 {...{
      "id": "non-optional-exact-milliseconds",
      "style": {
        "position": "relative"
      }
    }}>{`Non optional exact milliseconds`}</h2>
    <p>{`RFC 3339 defines `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`time-secfrac`}</code>{` optional as: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`time-secfrac    = "." 1*DIGIT (Meaning 1 or more DIGIT)`}</code></pre></div>
    <p>{`This allows for an unlimited numbers of digits. For this scalar the rule is not optional anymore and
refined as:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`time-secfrac    = "." DIGIT DIGIT DIGIT`}</code></pre></div>
    <p>{`consisting always of exact three digits representing milliseconds.`}</p>
    <h2 {...{
      "id": "no-unknown-local-offset-convention",
      "style": {
        "position": "relative"
      }
    }}>{`No 'Unknown Local Offset Convention'`}</h2>
    <p><a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc3339#section-4.3"
      }}>{`Section 4.3`}</a>{` says: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`If the time in UTC is known, but the offset to local time is unknown, 
this can be represented with an offset of "-00:00".`}</code></pre></div>
    <p>{`In order to simplify this Scalar this convention is dropped and an offset of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`-00:00`}</code>{` is not allowed.`}</p>
    <h1 {...{
      "id": "result-coercion",
      "style": {
        "position": "relative"
      }
    }}>{`Result Coercion`}</h1>
    <h2 {...{
      "id": "input",
      "style": {
        "position": "relative"
      }
    }}>{`Input`}</h2>
    <p>{`The accepted values are implementation specific, but it is recommend to accept Strings and other types representing Dates. `}</p>
    <h2 {...{
      "id": "output",
      "style": {
        "position": "relative"
      }
    }}>{`Output`}</h2>
    <p>{`The outcome of the result Coercion should always be a String formatted as "date-time" as described above.`}</p>
    <p>{`UTC offset should be formatted as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Z`}</code>{` and not `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+00:00`}</code>{` and lower case `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`z`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`t`}</code>{` should be converted to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Z`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`T`}</code>{`.`}</p>
    <h1 {...{
      "id": "literal-coercion",
      "style": {
        "position": "relative"
      }
    }}>{`Literal Coercion`}</h1>
    <h2 {...{
      "id": "input-1",
      "style": {
        "position": "relative"
      }
    }}>{`Input`}</h2>
    <p>{`Only String literals are accepted formatted as "date-time" as described above. `}</p>
    <h2 {...{
      "id": "output-1",
      "style": {
        "position": "relative"
      }
    }}>{`Output`}</h2>
    <p>{`The output value is implementation specific, but the value should accurately capture a "date-time" as described above. It is especially discouraged to use  a value with a time zone instead of an offset, because time zone to offset conversion is non trivial.   `}</p>
    <h1 {...{
      "id": "value-coercion",
      "style": {
        "position": "relative"
      }
    }}>{`Value Coercion`}</h1>
    <h2 {...{
      "id": "input-2",
      "style": {
        "position": "relative"
      }
    }}>{`Input`}</h2>
    <p>{`The specific values for the input are implementation specific, but they should be semantically equivalent to "date-time" without any conversion.
It is especially discouraged to accept values with time zones instead of offsets, because the conversion from time zone to offset is non trivial. `}</p>
    <h2 {...{
      "id": "output-2",
      "style": {
        "position": "relative"
      }
    }}>{`Output`}</h2>
    <p>{`Implementation specific.`}</p>
    <h1 {...{
      "id": "test-cases",
      "style": {
        "position": "relative"
      }
    }}>{`Test cases`}</h1>
    <p>{`These are valid Strings accepted for the input of Literal Coercion: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`String`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108Z`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A DateTime with UTC offset (+00:00).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108+00:00`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A DateTime with `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`+00:00`}</code>{` which is the same as UTC.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30t13:22:53.108z`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`z`}</code>{` and `}<code parentName="td" {...{
              "className": "language-text"
            }}>{`t`}</code>{` may be lower case.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108-03:00`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A DateTime with -3h offset.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108+03:30`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A DateTime with +3h 30min offset.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`These are invalid Strings which should be rejected as Literal Coercion Input and should not
be an output of Result Coercion:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`String`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Why is it invalid`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108-03`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The minutes of the offset are missing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108912Z`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many digits for fractions of a second. Exactly three expected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T24:22:53Z`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fractions of a second are missing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No offset provided.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No time provided.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108-00:00`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Negative offset (`}<code parentName="td" {...{
              "className": "language-text"
            }}>{`-00:00`}</code>{`) is not allowed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T13:22:53.108+03:30:15`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seconds are not allowed for the offset`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2011-08-30T24:22:53.108Z`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`24`}</code>{` is not allowed as hour of the time.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2010-02-30T21:22:53.108Z`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30th of February is not a valid date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`2010-02-11T21:22:53.108Z+25:11`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25 is not a valid hour for offset`}</td>
        </tr>
      </tbody>
    </table>
    <h1 {...{
      "id": "recommended-scalar-name",
      "style": {
        "position": "relative"
      }
    }}>{`Recommended Scalar name`}</h1>
    <p>{`The obvious recommended name for the Scalar is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`DateTime`}</code>{`.
If this name is not available then one alternative is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`OffsetDateTime`}</code>{`, which describes the Scalar quite accurately.`}</p>
    <Link to="/" mdxType="Link">Go back to the homepage</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      